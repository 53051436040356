body {
    background-color: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // overflow: hidden;
    min-height: 100vh;
    color: $color-white;
    /*right: 0;
    left: 0;
    bottom: 0;
    top: 0;*/
}