@import '_variables';
@import '_normalize';
@import '_mixins';
@import '_background';
@import '_settings.scss';

h1,
h2,
h3,
p {
    font-family: 'Lato', sans-serif;
}

//######### Center Column #########//
#center {
    display: block;
    top: 50%;
    width: 100%;
    text-align: center;
    h1#time {
        font-size: $font-size-jumbo;
        font-weight: $font-weight-h1;
        margin-bottom: 0;
    }
    .format {
        position: absolute;
        left: 102%;
        bottom: 9%;
        font-size: 150%;
        opacity: 0;
    }
    h2 {
        font-size: $font-size-h2;
        font-weight: $font-weight-h2;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
}

//######### Greeting Form #########//
#center-box {
    display: block;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    h3 {
        font-size: $font-size-h3;
        font-weight: $font-weight-h3;
    }
    input {
        width: 450px;
        border-bottom-width: 2px;
        display: block;
        font-size: $font-size-h2;
        background: 0;
        border: 0;
        border-bottom: 3px solid #fff;
        color: #fff;
        font-weight: 500;
        outline: 0;
        margin: 0 auto;
        text-align: center;
    }
}

//######### Main Focus #########//
#center-below {
    display: block;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    h3 {
        font-size: $font-size-h3;
        font-weight: inherit;
    }
    input {
        width: 450px;
        border-bottom-width: 2px;
        font-size: $font-size-h3; //120%
        display: block;
        background: 0;
        border: 0;
        border-bottom: 3px solid #fff;
        color: #fff;
        font-weight: 500;
        outline: 0;
        margin: 0 auto;
        text-align: center;
    }
}

//######### Toggle #########//
#todo {
    float: right;
    right: 0;
    position: relative;
}

.dropdown-menu {
    background-color: $color-drak-grey;
    li a {
        color: $color-grey;
    }
}

.dropdown-toggle {
    cursor: pointer;
}

#dropdown-menu-list {
    padding: 9px 15px 9px 15px;
    li {
        font-size: $font-size;
        padding: 5px 0 5px 0;
        a:hover {
            text-decoration: none;
            color: $color-off-white;
        }
    }
}

//######### Quote #########//
.quote {
    border-left: 0;
    margin-top: 30px;
    #quotation::before {
        font-family: FontAwesome;
        content: "\f10d";
    }
}

.tweet {
    #{$a-tags} {
        color: $color-white;
        text-decoration: none;
    }
    #{$a-tags-hover} {
        color: $color-grey;
    }
}

//######### Todo #########//
.todo-container {
    position: relative;
    display: inline-block;
}

.todo-pane {
    background: $color-drak-grey;
    right: 3px;
    text-align: left;
    display: none;
    bottom: 50px;
    position: absolute;
    padding: 15px;
    z-index: 9999;
    left: -300px;
    @include border-radius(5px);
    input {
        height: 36px;
        width: 100%;
        padding: 6px 15px 7px;
        float: left;
        color: rgba(255, 255, 255, 0.75);
        font-size: $font-size;
        text-indent: 18px;
        padding: 0 60px 0 0;
        margin-bottom: 25px;
        background: rgba(255, 255, 255, 0.2);
        border: 0;
        outline: none;
        @include border-radius(5px 5px 5px 5px);
        @include box-shadow(none);
        -webkit-appearance: none;
        -moz-appearance: none;
        @include placeholder();
    }
}

.toggle {
    position: relative;
    top: 0;
    padding-right: 15px;
    right: 0;
    cursor: pointer;
}

.container-todo-list {
    width: 100%;
    float: left; //padding: 15px;
    max-height: 350px;
    overflow: auto;
    ul.todo {
        width: 100%;
        float: left;
        padding: 0;
        li {
            width: 100%;
            float: left;
            min-height: 30px;
            font-size: $font-size;
            font-weight: $color-white;
            color: $color-grey;
            line-height: 30px;
            list-style: none;
            background: $color-light-grey;
            position: relative;
            margin: 0 0 10px 0;
            padding: 0 100px 0 7px;
            word-break: break-word;
            @include border-radius(5px);
            @include box-shadow(0px 1px 2px rgba(44, 62, 80, 0.10));
            @include even() {
                background: $color-light-grey;
            }
            @include odd() {
                background: $color-off-grey;
            }
            &:last-of-type {
                margin: 0;
            }
            .buttons {
                width: 50px;
                height: 7px;
                position: absolute;
                top: 0;
                right: 0;
                line-height: 30px;
                display: flex;
                justify-content: space-around;
                button {
                    width: 50px;
                    height: 7px;
                    float: left;
                    background: none;
                    position: relative;
                    border: 0px;
                    box-shadow: none;
                    outline: none;
                    cursor: pointer;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    &:last-of-type:before {
                        content: '';
                        width: 1px;
                        height: 30px;
                        background: $color-off-white;
                        position: absolute;
                        top: 10px;
                        left: 0;
                    }
                    i {
                        width: 21px;
                        height: 21px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: -11px 0 0 -11px;
                    }
                    &.complete i {
                        @include border-radius(11px);
                        border: 1.5px solid $color-green;
                        transition: background 0.2s ease;
                    }
                }
            }
        }
        &#completed li .buttons button.complete i {
            background: $color-green;
            border: 0px;
        }
        &:not(#completed) li .buttons button.complete:hover i {
            background: rgba(37, 185, 154, 0.75);
            .fill {
                fill: #fff;
            }
        }
        &#completed li .buttons button.complete i .fill {
            fill: #fff;
        }
        li .buttons button {
            i .fill {
                transition: fill 0.2s ease;
            }
            &.remove {
                i .fill {
                    fill: #c0cecb;
                }
                &:hover i .fill {
                    fill: #e85656;
                }
            }
            &.complete i .fill {
                fill: #25b99a;
            }
        }
        &#completed {
            position: relative;
            padding: 30px 0 0 0;
            &::before {
                content: '';
                width: 150px;
                height: 1px;
                background: $color-very-light-blue;
                position: absolute;
                top: 15px;
                left: 50%;
                margin: 0 0 0 -75px;
            }
        }
        &#todoList:empty:after {
            content: 'Your to-do is empty!';
            margin: 15px 0 0 0;
        }
        &#completed:empty:after {
            content: 'To-Do complete tasks.';
        }
        &#todoList:after,
        &#completed:after {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 12px;
            color: $color-grey;
        }
        .completed i:hover {
            cursor: pointer;
            color: $color-green;
        }
        .remove i:hover {
            cursor: pointer;
            color: $color-red;
        }
    }
}

//######### Top #########//
.cornerPadTop {
    padding-top: 20px;
    height: 70px;
}

//######### Weather #########//
.item {
    padding-left: 10px;
}

#weatherIcon {
    max-width: 25px;
    padding-top: 5px;
}

#tempId {
    font-size: x-large;
}

.degree {
    font-size: x-large;
}

#cityId {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: x-small;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; // margin-bottom: 5px;
}

//######### Mobile View Footer Position #########//
.mainTop {
    min-height: 93vh;
    display: flex;
    flex-direction: column;
    }

#main-box {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

#footer .row {
    max-height: 6vh;
}

//######### Footer - Vertical Alignment #########//
.v-adjust {
    display: flex;
    flex-direction: row;
    [class^="col-"],
    [class*="col-"] {
        display: flex;
        align-items: center;
    }
    .col-sm-8 {
        justify-content: center;
    }
}

#bottom-left {
    margin-bottom: 3%;
}

#bottom-right {
    justify-content: flex-end;
    margin-bottom: 3%;
}

//######### Main Focus #########//
.focusBox {
    display: flex;
    justify-content: center;
    font-size: $font-size-h3;
}

.focusItem {
    padding: 0px 20px;
}

.strike {
    text-decoration: line-through;
}

.focusCheckbox {
    cursor: hand;
    cursor: pointer;
    &:hover {
        color: #25b99a;
    }
}

.focusTrash {
    cursor: hand;
    cursor: pointer;
    &:hover {
        color: #e85656;
    }
}
