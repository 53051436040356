// Variables

$font-size-h1: 1050%;
$font-weight-h1: bold;
$font-size-h2: 337.5%;
$font-weight-h2: bold;
$font-size-h3: 187.5%;
$font-weight-h3: bold;
$font-size: 15px;
$font-size-jumbo: 1199%;
$font-weight: 500;
$color-white: #fff;
$color-off-white: #edf0f1;
$color-green: #25b99a;
$color-red: #ff0000;
$color-light-grey: #c9cfd4;
$color-drak-grey: #384047;
$color-grey: #7b8b8e;
$color-off-grey: #f9fafb;
$color-very-light-blue: #d8e5e0;
$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';

 