//######### Settings Switch #########//

/* The switch - the box around the slider (label) */

.switch {
    display: inline-block;
    width: 30px;
    height: 17px;
    position: relative;
    /* Hide default HTML checkbox (label > input) */
    input {
        display: none;
    }
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 17px;
    &:before {
        border-radius: 50%;
    }
}

//######### Settings Dropup Menu #########//
.settings-dropup {
    width: 300px; // Delete and style in .dropdown-menu
    background: rgb(0, 0, 0); // fallback
    background: rgba(0, 0, 0, 0.8);
    li a {
        color: $color-white;
        opacity: 0.8;
        &:hover {
            background-color: initial;
            color: $color-white;
            opacity: 1;
        }
    }
}

.settings-flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}